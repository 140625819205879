/* Hover CSS */

/* Icon Forward */
.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.hvr-icon-forward .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-forward:hover .hvr-icon, .hvr-icon-forward:focus .hvr-icon, .hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}

/* Icon Grow */
.hvr-icon-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-grow .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  vertical-align: middle;
}
.hvr-icon-grow:hover .hvr-icon, .hvr-icon-grow:focus .hvr-icon, .hvr-icon-grow:active .hvr-icon {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}





$total-items: 5;

$total-account-thumb-items: 5;


.row--acount-item {
  animation: fadeIn 0.5s linear;
  animation-fill-mode: both;
}

.account-product-thumb {
  animation: fadeIn 0.5s linear;
  animation-fill-mode: both;
}

div:not(.spinner):not(.cube1):not(.cube2):not(.row--acount-item) {
  animation: fadeIn 0.5s linear;
  animation-fill-mode: both;
}

// Set delay per List Item
@for $i from 1 through $total-items {
  .row--acount-item:nth-child(#{$i}) {
    animation-delay: .25s * $i;
  }
}

// Set delay per List Item
@for $i from 1 through $total-account-thumb-items {
  .account-product-thumb:nth-child(#{$i}) {
    animation-delay: .25s * $i;
  }
}

// Keyframe animation
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
/*    top: 100px;*/
  }
  75% {
    opacity: 0.5;
   /* top: 0px;*/
  }
  100% {
    opacity: 1;
  }
}


// @keyframes fadeIn {
//    0% {opacity: 0;}
//    100% {opacity: 1;}
// } 

//       div {
//             -webkit-animation-duration: .10s;
//             animation-duration: .10s;
//             -webkit-animation-fill-mode: both;
//             animation-fill-mode: both;
//          }
         
//          @-webkit-keyframes fadeIn {
//             0% {opacity: 0;}
//             100% {opacity: 1;}
//          }
         
//          @keyframes fadeIn {
//             0% {opacity: 0;}
//             100% {opacity: 1;}
//          }
         
//          div {
//             -webkit-animation-name: fadeIn;
//             animation-name: fadeIn;
//          }



.spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.cube1, .cube2 {
  background-color: $primary-color;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-cubemove 0.9s infinite ease-in-out;
  animation: sk-cubemove 0.9s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% { 
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% { 
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% { 
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% { 
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% { 
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
