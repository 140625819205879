
.text-danger {
	color: $warning-color;
}


.text-success {
	color: $success-color;
}

.text-light {
	font-weight: 200;
}

.text-dark {
	color: $white;
}

h1, h2, h3, h4, h5 {
	color: $white;
}