
#Footer {
  background: #1e1e1e;
  padding: 30px 0;
}
#NavBar {
  position: relative;
  z-index: 3;
}

.top-bar {
	z-index: 100;
	top: 0;
    position: relative;

    a {
    	color: $body-font-family;
    }
}

.top-bar__logo a {
	padding: 0 !important;
	margin-right: 32px;
}