

.page-wrapper {
  // position: relative;
}

.row--section {
  padding-top: 60px;
  padding-bottom: 64px;

  .row--section__title {
  	margin-bottom: 64px;
  }
}

.row--acount-item {
	border-bottom: 1px solid $dark-gray;
  margin-top: $global-margin * 2;
	margin-bottom: $global-margin * 2;
	padding-bottom: $global-padding * 2;

  .button-group {
    display: block;
  }
}



// bump up the homepage like a boss
.home {
top: -60px;
  position: relative;
}


.hero {
  top: -90px;
  background-color: #1e1e1e;
  padding-top: 196px;
  padding-bottom: 196px;

  // h1 {
  //   font-size: 60px;
  // }
}

.product-card {
  .product-card__thumbnail {
    width: 100%;
  } 

  h4 {
    margin-top: $global-margin;
  }
}

.thumb-container {
  // border: 1px solid $light-gray;
  overflow: hidden;
  position: relative;
}

.thumb--blur {
  filter: blur(6.5px);
}